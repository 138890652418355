import React from 'react';
import { Helmet } from 'react-helmet';

interface HeadScriptProps {
  microsoftClarityId: {
    dev: string;
    prod: string;
  };
  googleAnalyticsId: {
    dev: string;
    prod: string;
  };
}

function HeadScript({ microsoftClarityId, googleAnalyticsId }: HeadScriptProps) {
  const googleAnalyticsScript = (id: string) => (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${id}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${id}');
        `}
      </script>
    </Helmet>
  );

  const microsoftClarityScript = (id: string) => (
    <Helmet>
      <script type="text/javascript">
        {`
        (function (c, l, a, r, i, t, y) {
          c[a] = c[a] || function () {
            (c[a].q = c[a].q || []).push(arguments)
          };
          t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "${id}");
      `}
      </script>
    </Helmet>
  );

  const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
  const microsoftClarity = isDevelopment ? microsoftClarityId.dev : microsoftClarityId.dev;
  const googleAnalytics = isDevelopment ? googleAnalyticsId.dev : googleAnalyticsId.prod;

  return (
    <>
      {googleAnalyticsScript(googleAnalytics)}
      {microsoftClarityScript(microsoftClarity)}
    </>
  );
}

export default HeadScript;
